<template>
  <Dashboard>
    <div class="flex flex-col gap-12">
      <div v-if="!userStats || user.access_level < 5" class="flex-col lg:flex-row flex gap-6">
        <div class="flex flex-[1.5] flex-col gap-6">
          <h1 class="text-text-primary font-semibold text-[24px]">How to use Refrag</h1>
          <div class="flex rounded-lg overflow-hidden">
            <iframe
              src="https://customer-o1jnfnsdef83eyjx.cloudflarestream.com/a68fa80aadd03bbe160228d415553aa8/iframe?poster=https%3A%2F%2Fimagedelivery.net%2F5wML_ikJr-qv52ESeLE6CQ%2Fa4add990-8305-43fe-6c4e-c3b6faf97800%2Fpublic"
              loading="lazy"
              class="w-full aspect-[16/9]"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
            ></iframe>
          </div>
        </div>
        <div class="flex flex-col gap-6 flex-1">
          <h1 class="text-text-primary font-semibold text-[24px]">Stats</h1>
          <div class="flex flex-col sm:flex-row gap-6 w-full flex-1">
            <div
              :class="[eloGameType === 'crossfire' ? 'outline-primary-500 outline' : '']"
              class="flex flex-col flex-1 overflow-hidden items-center justify-center rounded-lg relative bg-gray-900 cursor-pointer duration-300"
            >
              <DoughnutChart
                v-if="userElos"
                class="w-[208px] h-[208px] flex-1 items-center justify-center flex"
                :chartData="getCrossfireData"
                :options="doughnutOptions"
              />
              <div
                v-if="userElos"
                class="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-center mt-3 flex-1"
              >
                <Pill
                  v-if="crossfireRankPercentage() > 0"
                  class="px-2 py-[2px] mb-3"
                  :text="getCrossfireRankPercentageString()"
                  :bgColor="`${getPercentageColor(crossfireRankPercentage())}30`"
                  :textColor="`${getPercentageColor(crossfireRankPercentage())}`"
                />
                <h2 class="text-text-secondary">Crossfire ELO</h2>
                <h1 class="text-text-primary font-semibold text-4xl">
                  {{ crossfireElo() }}
                </h1>
              </div>
              <div @click="launchCrossfire()" class="w-full z-50 bg-primary-600 py-3 flex gap-3 items-center justify-center text-text-primary text-xs cursor-pointer hover:bg-primary-500 duration-300">
                Play
                <svg
                  v-if="launchLoading === 'crossfire'"
                  width="16"
                  height="16"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="animate-spin"
                >
                  <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
                  <path
                    d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                    stroke="#4E54C8"
                    stroke-width="2"
                  />
                </svg>
                <ThumbUpIcon
                  v-if="launchSuccess === 'crossfire'"
                  class="w-4 h-4 text-success-400"
                />
              </div>
            </div>
            <div
              :class="[eloGameType === 'prefire' ? 'outline-primary-500 outline' : '']"
              class="flex flex-col flex-1 pt-2 overflow-hidden items-center justify-center rounded-lg relative cursor-pointer duration-300 bg-gray-900"
            >
              <DoughnutChart
                v-if="userElos"
                class="w-[208px] h-[208px] flex-1 items-center justify-center flex"
                :chartData="getPrefireData"
                :options="doughnutOptions"
              />
              <div
                v-if="userElos"
                class="absolute w-full h-full top-0 left-0 flex flex-col items-center justify-center mt-3"
              >
                <Pill
                  v-if="prefireRankPercentage() > 0"
                  class="px-2 py-[2px] mb-3"
                  :text="getPrefireRankPercentageString()"
                  :bgColor="`${getPercentageColor(prefireRankPercentage())}30`"
                  :textColor="`${getPercentageColor(prefireRankPercentage())}`"
                />
                <h2 class="text-text-secondary">Prefire ELO</h2>
                <h1 class="text-text-primary font-semibold text-4xl">
                  {{ prefireElo() }}
                </h1>
              </div>
              <div @click="launchPrefire()" class="w-full z-50 bg-primary-600 py-3 flex gap-3 items-center justify-center text-text-primary text-xs cursor-pointer hover:bg-primary-500 duration-300">
                Play
                <svg
                  v-if="launchLoading === 'prefire'"
                  width="16"
                  height="16"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="animate-spin"
                >
                  <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
                  <path
                    d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                    stroke="#4E54C8"
                    stroke-width="2"
                  />
                </svg>
                <ThumbUpIcon
                  v-if="launchSuccess === 'prefire'"
                  class="w-4 h-4 text-success-400"
                />
              </div>
            </div>
          </div>
          <div
            class="flex-1 w-full max-h-[150px] bg-gray-900 rounded-lg flex divide-x-2 divide-gray-950 rounded-lg overflow-hidden"
          >
            <div class="flex-1 flex flex-col gap-2 items-center justify-center py-10">
              <h2 class="text-text-secondary text-xs">Win Rate</h2>
              <h1 class="text-text-primary font-medium text-xl">
                {{ userRecentStats?.win_rate || 0 }}%
              </h1>
            </div>
            <div class="flex-1 flex flex-col gap-2 items-center justify-center py-10">
              <h2 class="text-text-secondary text-xs">K/D Ratio</h2>
              <h1 class="text-text-primary font-medium text-xl">
                {{ userRecentStats?.kd_ratio || 0 }}
              </h1>
            </div>
            <div class="flex-1 flex flex-col gap-2 items-center justify-center py-10">
              <h2 class="text-text-secondary text-xs">Headshot %</h2>
              <h1 class="text-text-primary font-medium text-xl">
                {{ userRecentStats?.hs_percentage || 0 }}%
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex grid lg:grid-cols-12 w-full gap-6">
        <div class="col-span-9 flex flex-col gap-4">
          <h1 class="text-text-primary font-semibold text-[24px]">Refrag Coach</h1>
          <div class="bg-gray-900 rounded-lg flex flex-col flex-grow">
            <nav class="w-full grid grid-cols-3 items-center justify-center relative">
              <div v-for="(stat, index) in userStats.slice(0, 3)" :key="index"
                class="py-3 flex flex-col gap-2 col-span-1 items-center justify-center cursor-pointer relative border-b border-gray-800"
                @click="selectStat(stat)"
              >
                <h2 class="text-text-secondary text-xs">{{ toTitleCase(formatStatString(stat.data.name)) }}</h2>
              </div>
              <div class="sliding-border" :style="{ left: slidingBorderPosition }"></div>
            </nav>

            <div class="flex p-4 gap-16 flex-grow">
              <div class="flex-1 flex flex-col gap-3">
                <h1 class="text-text-primary font-medium text-lg">
                  {{ toTitleCase(formatStatString(selectedStat.data.name)) }}
                </h1>

                <div class="text-text-secondary text-sm xl:w-3/4">
                   Your {{ formatStatString(selectedStat.data.name) }} average was <span class="text-danger-400">{{Math.abs(parseFloat(parseFloat(selectedStat.data.percent_difference).toFixed(2)))}}% worse</span> than your ranks average.
                </div>

                <div class="w-full h-px bg-gray-800 xl:w-4/5"/>

                <div class="text-text-secondary text-sm xl:w-3/4">
                  To improve your {{ formatStatString(selectedStat.data.name) }}, play the prepare set by clicking the button below.
                </div>

                <!-- <button @click="launchRecommendedMod(selectedStat.data.recommended_mods[0].modifier.modifier_name, true)" class="mt-auto py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2">
                  Improve Now
                  <svg
                    v-if="launchLoading === 'prepare_set'"
                    width="16"
                    height="16"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="animate-spin"
                  >
                    <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
                    <path
                      d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                      stroke="#4E54C8"
                      stroke-width="2"
                    />
                  </svg>
                  <ThumbUpIcon
                    v-if="launchSuccess === 'prepare_set'"
                    class="w-4 h-4 text-success-400"
                  />
                  <ArrowRightIcon class="w-4 h-4 text-text-primary" />
                </button> -->
              </div>

              <div class="flex-1 flex items-stretch p-2">
                <div class="flex-1 bg-gray-800 rounded-lg border-gray-700 border p-5 flex flex-col">
                  <div class="flex-1 flex gap-8">
                    <div class="flex flex-col gap-1">
                      <div class="flex gap-1 items-center">
                        <div class="h-2 w-2 rounded-sm bg-primary-500"/>
                        <h2 class="text-text-secondary text-xs">Your Avg.</h2>
                      </div>
                      <h1 class="text-text-primary text-lg font-medium">{{ formatStatStringData(selectedStat.data.players_value, selectedStat.data.unit_type) }}</h1>
                    </div>
                    <div class="flex flex-col gap-1">
                      <div class="flex gap-1 items-center">
                        <div class="h-2 w-2 rounded-sm bg-secondary-500"/>
                        <h2 class="text-text-secondary text-xs">Target Avg.</h2>
                      </div>
                      <h1 class="text-text-primary text-lg font-medium">{{ formatStatStringData(selectedStat.data.rating_range_average_value, selectedStat.data.unit_type) }}</h1>
                    </div>
                  </div>
                  <LineChart 
                    v-if="chartData" 
                    :options="options" 
                    :chartData="chartData" 
                    class="h-[112px]"
                  />
                  <div v-else class="h-[112px] flex items-center justify-center">
                    Loading chart data...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-4 col-span-3 justify-start">
          <h1 class="text-text-primary font-semibold text-[24px]">Recommended</h1>
          <div class="bg-gray-900 rounded-lg p-4 flex flex-col gap-2 flex-grow">
            <div class="w-16 h-16 bg-gray-800 border border-gray-700 rounded-full flex items-center justify-center mb-2">
              <img :src="`/maps/${recommendedMap.name}_pin.png`" class="w-8 h-8">
            </div>
            <div class="flex gap-1 items-center">
              <h2 class="text-text-secondary text-[10px] uppercase tracking-[1.5px]">{{ recommendedMap.friendly_name }}</h2>
              <span class="text-text-disabled text-[12px] uppercase tracking-[1.5px]">•</span>
              <!-- <h2 class="text-text-secondary text-[10px] uppercase tracking-[1.5px]">{{ userStats[0].data.recommended_mods[0].modifier.friendly_name }}</h2> -->
            </div>

            <h1 class="text-text-primary">
              Improve now with a recommended mod
            </h1>
            
            <!-- <h2 class="text-text-secondary text-xs font-light flex-1">
              Your Refrag coach has recommended {{ userStats[0].data.recommended_mods[0].modifier.friendly_name }} to improve your {{ formatStatString(userStats[0].data.name) }} on your most-played map, {{ recommendedMap.friendly_name }}. Get better now!
            </h2> -->

            <!-- <button @click="launchRecommendedMod(userStats[0].data.recommended_mods[0].modifier.modifier_name, false)" class="mt-auto py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-gray-800 hover:bg-gray-700 flex items-center justify-center gap-2">
              Play now
            </button> -->
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-6">
        <h1 class="text-text-primary font-semibold text-[24px]">Featured Modes</h1>

        <div class="grid grid-cols-1 xl:grid-cols-2 min-[1980px]:grid-cols-4 gap-6 items-center w-full">
          <div v-for="mod in featuredMods" class="bg-gray-900 rounded-lg p-4 flex-1 flex gap-4 col-span-1">
            <img :src="'/img/' + mod.name.toLowerCase() + '-image.jpg'" class=" flex-1 rounded-lg max-w-[260px]" />
            <div class="flex-1 flex flex-col gap-3">
              <h1 class="text-text-primary font-medium text-lg">{{ mod.name }}</h1>
              <p class="text-text-secondary font-light text-sm flex-1">
                {{ mod.description }}
              </p>
              <button
                @click="launchFeaturedMod(mod)"
                class="py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2"
              >
                Play now
                <svg
                  v-if="launchLoading === mod.id"
                  width="16"
                  height="16"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="animate-spin"
                >
                  <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
                  <path
                    d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                    stroke="#4E54C8"
                    stroke-width="2"
                  />
                </svg>
                <ThumbUpIcon
                  v-if="launchSuccess === mod.id"
                  class="w-4 h-4 text-success-400"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-6">
        <h1 class="text-text-primary font-semibold text-[24px]">What's New</h1>
        <div
          v-if="recentNews.length == 0"
          class="bg-gray-900 p-4 text-text-secondary text-xs tracking-[1.5px] w-full"
        >
          There's nothing new right now, check back later!
        </div>
        <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div
            v-for="(post, index) in recentNews"
            class="flex flex-col bg-gray-900 rounded-lg p-4 cols-span-1 w-full gap-6"
          >
            <img
              class="w-full aspect-[16/9] object-cover rounded-lg"
              :src="post.image_url"
            />
            <div class="flex flex-col gap-3 flex-1">
              <h2
                class="text-text-secondary font-light text-[10px] uppercase tracking-[1.5px]"
              >
                New {{ getContentTypeText(post?.content_type) }}
              </h2>
              <h1 class="text-text-primary font-medium text-lg">{{ post?.title }}</h1>
              <p class="text-text-secondary font-light text-sm flex-1">
                {{ post?.description }}
              </p>
            </div>
            <button
              @click="executePostAction(post)"
              class="py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 flex items-center justify-center gap-2"
              :class="[
                index === 0
                  ? 'bg-primary-600 hover:bg-primary-500'
                  : 'bg-gray-800 hover:bg-gray-700',
              ]"
            >
              {{ getButtonText(post) }}
              <svg
                v-if="launchLoading === post.id"
                width="16"
                height="16"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="animate-spin"
              >
                <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
                <path
                  d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                  stroke="#4E54C8"
                  stroke-width="2"
                />
              </svg>
              <ThumbUpIcon
                v-if="launchSuccess === post.id"
                class="w-4 h-4 text-success-400"
              />
            </button>
          </div>
        </div>
      </div>

      <div id="match-history" class="flex flex-col gap-6">
        <h1 class="text-text-primary font-semibold text-[24px]">Match History</h1>
        <div class="w-full flex rounded-lg flex-col gap-[2px] overflow-hidden">
          <div
            class="bg-gray-900 text-text-primary text-xs tracking-[1.5px] flex-1 p-4 uppercase flex md:gap-2 items-center"
          >
            <div class="flex-1 md:flex-none">Last 10 Matches</div>
            <span class="hidden md:block text-text-secondary">•</span>
            <span
              class="hidden md:block"
              :class="{
                'text-success-400': userRecentStats?.win_rate > 50,
                'text-text-primary': userRecentStats?.win_rate === 50,
                'text-danger-400': userRecentStats?.win_rate < 50,
              }"
            >
              {{ userRecentStats?.win_rate }}%
            </span>
            <h1 class="flex-1 hidden md:block">Win Ratio</h1>
            <svg
              v-if="loadingMatches"
              width="16"
              height="16"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="animate-spin"
            >
              <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
              <path
                d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                stroke="#4E54C8"
                stroke-width="2"
              />
            </svg>
            <button
              @click="retrieveMatches()"
              class="bg-gray-800 rounded-lg py-2 px-3 text-text-primary text-xs hover:bg-gray-700 duration-300"
            >
              Get Recent Matches
            </button>
          </div>

          <div v-if="matches.length > 0" v-for="match in matches" class="bg-gray-900">
            <MatchRow :match="match" @toggleAllstar="openMatchHighlightModal" />
          </div>
          <div
            v-else
            class="bg-gray-900 p-4 text-text-secondary text-xs tracking-[1.5px]"
          >
            No Matches Found, make sure to add your Faceit ID and Steam tokens to your
            account in
            <router-link to="/settings" class="underline">settings!</router-link>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    :showMapSelector="showMapSelector"
    @close="showServerModal = false"
  />
  <MatchHighlightsModal
    :show="showMatchHighlightsModal"
    :clipId="selectedMatchHighlightId"
    @close="closeMatchHighlightModal()"
  >
  </MatchHighlightsModal>
</template>

<script setup>
import Dashboard from "../components/layouts/DashboardLayout.vue";
import { ref, watch, computed, onMounted, onUnmounted } from "vue";
import { DoughnutChart, LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import MatchRow from "../components/UserProfile/MatchRow.vue";
import StartServerModal from "../components/StartServerModal.vue";
import { ThumbUpIcon, ArrowRightIcon } from "@heroicons/vue/solid";
import Pill from "../components/Pill.vue";
import MatchHighlightsModal from "../components/MatchHighlightsModal.vue";
import { useHead } from "@unhead/vue";
import { formatStatString, toTitleCase, formatData, formatStatStringData } from "../utils/formatters";
import { createClickablePointsPlugin } from '../utils/chartPlugins';

const route = useRoute();
const router = useRouter();
const store = useStore();
const auth = useAuth();
const recentNews = ref([]);
const userRecentStats = ref([]);
const showServerModal = ref(false);
const serverInfo = ref();
const activeServer = computed(() => store.getters.activeServer);
const launchLoading = ref(false);
const launchSuccess = ref(false);
const matches = ref([]);
const userElos = ref(null);
const loadingMatches = ref(false);
const selectedMatchHighlightId = ref(null);
const showMatchHighlightsModal = ref(false);
const showMapSelector = ref(false);
const userStats = ref();
const userStatsHistory = ref([]);
const selectedStat = ref(null);
const statAverage = ref(0);
const mappedStats = ref([]);
const recommendedMap = ref(null);
const user = ref(auth.user());

useHead({
  title: "Refrag | Dashboard",
});

Chart.register(...registerables);

// Create the plugin
const clickablePointsPlugin = createClickablePointsPlugin(
  (index) => userStatsHistory.value[userStatsHistory.value.length - 1 - index]?.match_id,
  (matchId) => router.push(`/matches/${matchId}`)
);

// Register the plugin
Chart.register(...registerables, clickablePointsPlugin);

const featuredMods = computed(() => {
  return store.state.modInfo?.mods.filter((mod) => mod.featured);
});

const slidingBorderPosition = computed(() => {
  const index = userStats.value.findIndex(stat => stat.data.name === selectedStat.value.data.name);
  return `${index * 33.33}%`;
});

onMounted(() => {
  if (route.query.game_mode) {
      serverInfo.value = {
        mode: route.query.game_mode,
      };
      showMapSelector.value = true;
      showServerModal.value = true;
  }
});

function selectStat(stat) {
  selectedStat.value = stat;
  updateUserStatHistory(stat);
}

function launchRecommendedMod(mod, showMap = false) {
  if (showMap) {
    showMapSelector.value = true;
    serverInfo.value = {
      mode: mod,
    };
    showServerModal.value = true;
  } else {
    showMapSelector.value = false;
    serverInfo.value = {
      mode: mod,
      cs_map: recommendedMap.value
    };
    showServerModal.value = true;
  }
}

function getRefragCoachData() {
  if (user.value.access_level < 5) {
    return;
  }

  const token = auth.token();
  const tokenData = token.split("|");
  const accessToken = tokenData[1];
  const client = tokenData[2];
  const uid = tokenData[3];

  axios.get(`${import.meta.env.VITE_COACH_URL}/players/${user.value.steam_id}/profile`, {
    headers: {
      'access-token': accessToken,
      'client': client,
      'uid': uid
    },
  }).then((response) => {
    userStats.value = findWorstDeviationsAway(response?.data?.stats) || [];
    recommendedMap.value = store.state.modInfo?.mods[0]?.cs_maps.find((map) => map.name === response?.data?.extra?.most_played_map) || store.state.modInfo?.mods[0]?.cs_maps[0];
    selectedStat.value = userStats.value[0];
  }).catch((error) => {

  });

  axios.get(`${import.meta.env.VITE_COACH_URL}/players/${user.value.steam_id}/recent_match_stats`, {
    headers: {
      'access-token': accessToken,
      'client': client,
      'uid': uid
    }
  }).then((response) => {
    userStatsHistory.value = response.data;
    updateUserStatHistory(selectedStat.value);
  }).catch((error) => {

  });
}

function findWorstDeviationsAway(stats) {
  const worstStats = [];

  Object.keys(stats).forEach((statKey) => {
    const stat = stats[statKey];

    if (stat.deviations_away !== undefined) {
      const playersValue = stat.players_value;
      const averageValue = stat.rating_range_average_value;
      let percentageDifference = 0;
      
      if (averageValue !== 0) {
        if (stat.is_ascending) {
          percentageDifference = ((averageValue - playersValue) / averageValue) * 100;
        } else {
          percentageDifference = ((playersValue - averageValue) / averageValue) * 100;
        }
      }
      
      stat.percent_difference = percentageDifference.toFixed(2);
      worstStats.push({title: statKey, data: stat});
    }
  });

  worstStats.sort((a, b) => parseFloat(b.data.percent_difference) - parseFloat(a.data.percent_difference));
  return worstStats.slice(0, 3);
}

getRefragCoachData()

function launchCrossfire() {
  const mod = featuredMods.value.find((mod) => mod.name.toLowerCase() === "crossfire");
  launchLoading.value = 'crossfire';
  launchFeaturedMod(mod);
}

function launchPrefire() {
  const mod = featuredMods.value.find((mod) => mod.name.toLowerCase() === "prefire");
  launchLoading.value = 'prefire';
  launchFeaturedMod(mod);
}

function launchFeaturedMod(mod) {
  if (!activeServer.value?.id) {
    launchLoading.value = null;
    showMapSelector.value = true;
    serverInfo.value = {
      mode: mod.name,
    };
    showServerModal.value = true;
    return;
  }

  if (!launchLoading.value) {
    launchLoading.value = mod.id;
  }
  axios({
    url: `/cs_servers/${activeServer.value.id}/exec_server_command`,
    method: "POST",
    params: {
      command: "change_mod",
      value: mod.name,
    },
    headers: {
      "X-TEAM-ID": store.getters.activeTeam.id,
    },
  })
    .then(() => {
      launchSuccess.value = launchLoading.value;
      launchLoading.value = null;
      setTimeout(() => {
        launchSuccess.value = null;
      }, 3000);
    })
    .catch((err) => {
      launchLoading.value = null;
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not communicate with server",
      });
      setTimeout(() => {
        store.commit("hideMessage");
      }, 3000);
    });
}

function updateModalServerInfo(launchSettings) {
  serverInfo.value = launchSettings;
  showServerModal.value = true;
}

function openMatchHighlightModal(clipId) {
  selectedMatchHighlightId.value = clipId;
  showMatchHighlightsModal.value = true;
}

function closeMatchHighlightModal() {
  showMatchHighlightsModal.value = false;
}

function getModName(type) {
  if (type === "set") {
    return "Creator Studio";
  } else if (type === "grenade group") {
    return "Grenade Groups";
  } else if (type === "strategy") {
    return "Strategies";
  }
}

function launchPostContent(post, type) {
  let content = post.content;
  if (!activeServer.value?.id) {
    let launchSettings = {
      cs_map: content.cs_map,
      mode: getModName(type),
      content: content,
    };
    updateModalServerInfo(launchSettings);
    return;
  }

  let command = "";
  let value = "";

  if (type === "set") {
    command = "load_set";
    value = `custom_set, ${content.id}`;
  } else if (type === "grenade group") {
    command = "load_nadr";
    value = `grenades, ${content.id}`;
  } else if (type === "strategy") {
    command = "load_nadr";
    value = `strat, ${content.id}`;
  }

  launchLoading.value = post.id;

  axios({
    url: `/cs_servers/${activeServer.value.id}/exec_server_command`,
    method: "POST",
    params: {
      command: command,
      value: value,
    },
    headers: {
      "X-TEAM-ID": store.getters.activeTeam.id,
    },
  })
    .then(() => {
      launchLoading.value = null;
      launchSuccess.value = post.id;
      setTimeout(() => {
        launchSuccess.value = null;
      }, 3000);
    })
    .catch((err) => {
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not communicate with server",
      });
      setTimeout(() => {
        store.commit("hideMessage");
      }, 3000);
      launchLoading.value = null;
    });
}

function executePostAction(post) {
  switch (post.content_type) {
    case "feature":
      router.push(`${post.content_link}`);
      break;
    case "CustomSet":
      launchPostContent(post, "set");
      break;
    case "GrenadeGroup":
      launchPostContent(post, "grenade group");
      break;
    case "blog":
      window.open(post.content_link, "_blank");
      break;
    case "external":
      window.open(post.content_link, "_blank");
      break;
    default:
      break;
  }
}

function getButtonText(post) {
  switch (post.content_type) {
    case "feature":
      return "View Feature";
    case "CustomSet":
      return "Play Now";
    default:
      return "Read More";
  }
}

function getContentTypeText(type) {
  switch (type) {
    case "feature":
      return "Feature";
    case "external":
      return "Content";
    case "CustomSet":
      return "Featured Set";
    case "GrenadeGroup":
      return "Featured Grenade Group";
    case "blog":
      return "Blog";
    default:
      return "News";
  }
}

const doughnutOptions = {
  rotation: 242, // start angle in degrees
  circumference: 240, // sweep angle in degrees
  borderWidth: 0,
  backgroundColor: ["#1E2733"],
  radius: "100%",
  cutout: "90%",
};

const getCrossfireData = computed(() => {
  return {
    datasets: [
      {
        data: [crossfireRankPercentage(), 1 - crossfireRankPercentage()],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return context.dataIndex === 0
            ? getPercentageRankGradient(ctx, chartArea, crossfireRankPercentage())
            : "#1E2733";
        },
      },
    ],
  };
});

const getPrefireData = computed(() => {
  return {
    datasets: [
      {
        data: [prefireRankPercentage(), 1 - prefireRankPercentage()],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return context.dataIndex === 0
            ? getPercentageRankGradient(ctx, chartArea, prefireRankPercentage())
            : "#1E2733";
        },
      },
    ],
  };
});

function getPercentageColor(percent) {
  switch (true) {
    case percent >= 0.9:
      return "#FFD700";
    case percent >= 0.8:
      return "#EB4A49";
    case percent >= 0.6:
      return "#F453FB";
    case percent >= 0.4:
      return "#6A7DFF";
    case percent >= 0.2:
      return "#85B5E5";
    default:
      return "#B8C7D7";
  }
}

function getPercentageRankGradient(ctx, chartArea, percent) {
  let startAngle = (-45 * Math.PI) / 180;
  var gradient = ctx.createConicGradient(
    startAngle,
    chartArea.width / 2,
    chartArea.height / 2
  );
  if (percent >= 0.9) {
    gradient.addColorStop(0, "#FFD700");
    gradient.addColorStop(0.5, "#FFD70070");
    gradient.addColorStop(1, "#FFD700");
    return gradient;
  }
  if (percent >= 0.8) {
    gradient.addColorStop(0, "#EB4A49");
    gradient.addColorStop(0.5, "#EB4A4970");
    gradient.addColorStop(1, "#EB4A49");
    return gradient;
  }
  if (percent >= 0.6) {
    gradient.addColorStop(0, "#F453FB");
    gradient.addColorStop(0.5, "#F453FB70");
    gradient.addColorStop(1, "#F453FB");
    return gradient;
  }
  if (percent >= 0.4) {
    gradient.addColorStop(0, "#6A7DFF");
    gradient.addColorStop(0.5, "#6A7DFF70");
    gradient.addColorStop(1, "#6A7DFF");
    return gradient;
  }
  if (percent >= 0.2) {
    gradient.addColorStop(0, "#85B5E5");
    gradient.addColorStop(0.5, "#85B5E570");
    gradient.addColorStop(1, "#85B5E5");
    return gradient;
  }
  gradient.addColorStop(0, "#B8C7D7");
  gradient.addColorStop(0.5, "#B8C7D770");
  gradient.addColorStop(1, "#B8C7D7");
  return gradient;
}

function crossfireElo() {
  const elos = userElos.value?.elos.filter((elo) => elo.mod.toLowerCase() == "crossfire");
  if (!elos.length) return 0;

  return elos[0].elo;
}

function crossfireRankPercentage() {
  const elos = userElos.value?.elo_ranks_percentage.filter(
    (elo) => elo.mod.toLowerCase() == "crossfire"
  );
  if (!elos.length) return "N/A";

  return elos[0].rank;
}

function getCrossfireRankPercentageString() {
  return `Top ${((1 - crossfireRankPercentage()) * 100).toFixed(2)}%`;
}

function prefireRankPercentage() {
  const elos = userElos.value?.elo_ranks_percentage.filter(
    (elo) => elo.mod.toLowerCase() == "prefire"
  );
  if (!elos.length) return "N/A";

  return elos[0].rank;
}

function getPrefireRankPercentageString() {
  return `Top ${((1 - prefireRankPercentage()) * 100).toFixed(2)}%`;
}

function prefireElo() {
  const elos = userElos.value?.elos.filter((elo) => elo.mod.toLowerCase() == "prefire");
  if (!elos.length) return 0;

  return elos[0].elo;
}

function retrieveMatches() {
  loadingMatches.value = true;
  axios
    .post("/matches/retrieve_my_matches")
    .then(() => {
      store.commit("showMessage", {
        messageType: "success",
        messageText: "Retrieving matches, this may take a while.",
      });
    })
    .catch(() => {
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not retrieve matches, please try again later.",
      });
    })
    .finally(() => {
      loadingMatches.value = false;
      setTimeout(() => {
        store.commit("hideMessage");
      }, 3000);
    });
}

axios
  .get("/news_posts")
  .then((response) => {
    recentNews.value = response.data;
  })
  .catch((error) => {
    console.error(error);
  });

axios
  .get("/matches/my_history")
  .then((response) => {
    matches.value = response.data;
    if (route.hash === "#matchhistory") {
      setTimeout(() => {
        document.getElementById("match-history").scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);
    }
  })
  .catch((error) => {
    console.error(error);
  });

axios
  .get("/recent_matches_stats")
  .then((response) => {
    userRecentStats.value = response.data;
  })
  .catch((error) => {
    console.error(error);
  });

axios
  .get("statistics/my_elos")
  .then((response) => {
    userElos.value = response.data.elo_stats;
  })
  .catch((error) => {
    console.error(error);
  });

function updateUserStatHistory(stat) {
  let mappedStatHistory = userStatsHistory.value?.map((match) => {
    return formatData(match[selectedStat.value.title], stat.data.unit_type);
  });

  statAverage.value = formatData(stat.data.rating_range_average_value, stat.data.unit_type);
  mappedStats.value = mappedStatHistory;
}

const labels = computed(() => {
  return userStatsHistory.value?.map((match) => {
    return match.date.split("T")[0];
  });
});
  
// In your options object
const options = computed(() => ({
  spanGaps: true,
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
    title: {
      display: false, // Hide the title
    },
    clickablePoints: clickablePointsPlugin,
  },
  elements: {
    point: {
      radius: 4,
      hoverRadius: 6,
    },
  },
  scales: {
    x: {
      display: false, // Hide the X-axis labels and grid lines
      grid: {
        color: 'transparent',
        borderColor: 'transparent'
      },
    },
    y: {
      grid: {
        borderDash: [5, 5],
        color: '#37485D',
        borderColor: 'transparent'
      },
      ticks: {
        color: '#9CADC4',
        maxTicksLimit: 5
      },
    }
  }
}));

// Chart.js data function
const chartData = computed(() => {
  if (userStatsHistory.value.length === 0 || !selectedStat.value) {
    return null;
  }
  
  // Create reversed arrays for labels and data
  const reversedLabels = [...userStatsHistory.value].reverse().map(match => match.date.split('T')[0]);
  const reversedData = [...userStatsHistory.value].reverse().map(match => 
    formatData(match[selectedStat.value.title], selectedStat.value.data.unit_type)
  );
  
  return {
    labels: reversedLabels,
    datasets: [
      {
        data: reversedData,
        fill: true,
        backgroundColor: "#6B70D115",
        borderColor: "#6B70D1",
        tension: 0,
        pointRadius: 3,
        pointHoverBackgroundColor: '#6B70D1',
        pointHoverBorderColor: '#FFFFFF',
      },
      {
        data: Array(userStatsHistory.value.length).fill(statAverage.value),
        fill: false,
        borderColor: "#C44CB6",
        borderDash: [5, 5],
        pointRadius: 0,
        borderWidth: 1,
      },
    ],
  }
});
</script>

<style scoped>
.sliding-border {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 33.33%;
  background-color: theme('colors.primary.500');
  transition: left 0.3s ease-in-out;
}
</style>