<script setup>
import DashboardLayout from "../../components/layouts/DashboardLayout.vue";
import StatBlock from "../../components/Restrat/Demo/StatBlock.vue";

import moment from "moment";
import { PlusIcon } from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon, InformationCircleIcon, ArrowRightIcon, UploadIcon, DownloadIcon } from "@heroicons/vue/solid";
import StartServerModal from "../../components/StartServerModal.vue";
import { mapMutations } from "vuex";
</script>

<template>
  <DashboardLayout title="Leaderboards">
    <div class="grid grid-cols-1 gap-4">
      <div>
        <div class="flex flex-col gap-3 lg:flex-row items-center justify-center border-b border-gray-800 pb-9">
          <h3 class="text-text-primary text-4xl font-semibold flex-grow">
            Leaderboards
          </h3>

          <div class="flex flex-col lg:flex-row gap-4 items-center w-full lg:w-auto">

            <div class="flex gap-4 w-full lg:w-auto">
              <div v-if="activeMod !== 'Rapid'" class="flex flex-row bg-gray-800 h-8 p-1 rounded-md w-1/2 lg:w-auto">
                <div
                  @click="selectScoreType('score')"
                  class="w-full lg:w-16 flex gap-2 text-sm items-center font-light justify-center rounded-md p-1 cursor-pointer"
                  :class="[scoreType === 'score' ? 'bg-gray-700 text-text-primary' : 'text-text-secondary bg-gray-800']"
                >
                  Score
                </div>
                <div
                  @click="selectScoreType('elo')"
                  class="w-full lg:w-16 flex gap-2 text-sm items-center justify-center font-light rounded-md p-1 cursor-pointer"
                  :class="[scoreType === 'elo' ? 'bg-gray-700 text-text-primary' : 'text-text-secondary bg-gray-800']"
                >
                  Elo
                </div>
              </div>
            </div>

            <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
              <MenuButton
                class="relative w-full lg:w-auto disabled:bg-gray-800 disabled:text-text-disabled inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
              >
                <div class="flex-1 text-left pr-4 flex items-center gap-1">
                  <span class="text-text-secondary">Mod:</span> {{ activeMod }}
                  <img v-if="activeMod === 'Rapid'" class="w-5 h-5" :src="`/img/icons/wooting.svg`" />
                </div>
                <ChevronDownIcon class="-mr-1 h-5 w-5" aria-hidden="true" />
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="py-1 flex flex-col px-4">
                    <MenuItem
                      :key="mod"
                      v-for="mod in mods"
                      v-slot="{ active }"
                      @click="selectMod(mod)"
                    >
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start',
                      ]"
                    >
                      {{ mod }}
                      <img v-if="mod === 'Rapid'" class="w-5 h-5 ml-1" :src="`/img/icons/wooting.svg`" />
                      <CheckIcon v-if="mod === activeMod" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>

            <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
              <MenuButton
                :disabled="scoreType === 'elo'"
                class="relative w-full lg:w-auto disabled:bg-gray-800 disabled:text-text-disabled inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
              >
                <div class="flex-1 text-left">
                  <span class="text-text-secondary">Season:</span> {{ season - 1 }}
                </div>
                <ChevronDownIcon class="-mr-1 h-5 w-5" aria-hidden="true" />
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="py-1 flex flex-col px-4">
                    <MenuItem
                      :key="num"
                      v-for="num in [2, 3, 4, 5]"
                      v-slot="{ active }"
                      @click="selectSeason(num)"
                    >
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start',
                      ]"
                    >
                      {{ num - 1 }}
                      <CheckIcon v-if="num === season" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>

            <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
              <MenuButton
                :disabled="scoreType === 'elo'"
                class="relative w-full lg:w-auto disabled:bg-gray-800 disabled:text-text-disabled inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
              >
                <div class="flex-1 text-left">
                  <span class="text-text-secondary">Map:</span> {{ getMapFriendlyName(selected_map) }}
                </div>
                <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="py-1 flex flex-col px-4">
                    <MenuItem
                      :key="map"
                      v-for="map in cs_maps"
                      v-slot="{ active }"
                      @click="selectMap(map)"
                    >
                      <a
                        href="#"
                        :class="[
                          active ? 'text-text-primary' : 'text-text-secondary',
                          'group flex items-center py-2 text-sm w-full justify-start',
                        ]"
                      >
                        {{ map.friendly_name }}
                        <CheckIcon v-if="map.id === selected_map?.id" class="h-5 w-5 ml-auto text-primary-400" />
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>

        <div id="leaderboards" class="py-6">
          <svg
            class="animate-spin h-full font-normal w-16 text-text-primary m-auto py-20"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            v-if="userStatsLoading || globalStatsLoading"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>

          <div v-if="!userStatsLoading && !globalStatsLoading">
            <div class="flex flex-col lg:flex-row lg:space-x-5">
              <!-- global scores -->
              <div class="flex-1 pt-10">
                <div class="flex items-center w-full">
                  <div class="flex-1">
                    <div v-if="activeMod !== 'Rapid'" class="text-text-primary leading-6 font-medium text-[24px] pb-3 tracking-[.5px]">Global Top 10</div>
                    <div v-else class="flex text-text-primary leading-6 font-medium text-[24px] pb-3 tracking-[.5px] items-center gap-3 flex-1">
                      <img class="w-6 h-6" src="/img/icons/wooting.svg" />
                      <h1>Rapid by Wooting Top 50</h1>
                    </div>
                    <h2 v-if="activeMod !== 'Rapid'" class="text-text-secondary text-sm">Total Attempts ― {{ getTotalAttempts() }}</h2>
                    <h2 v-else class="text-text-secondary text-sm">Compete to win a brand new Wooting keyboard!</h2>
                  </div>
                  <div v-if="activeMod === 'Rapid'" @click="launchRapid" class="px-3 py-2.5 text-text-primary rounded-lg bg-primary-600 hover:bg-primary-500 duration-300 text-xs cursor-pointer">
                    Play Rapid
                  </div>
                </div>
                <table v-if="scores.length > 0" class="w-full mt-5">
                  <thead class="bg-gray-900 shadow h-10 flex flex-row text-text-secondary text-xs px-6 items-center justify-center border-b-2 border-gray-950 rounded-t-lg w-full gap-6">
                    <th class="flex-1 flex flex-row items-start font-light">Name</th>
                    <th class="flex flex-row sm:min-w-24 items-end justify-end font-light">Score</th>
                    <th v-if="activeMod === 'Rapid'" class="flex flex-row sm:min-w-24 items-end justify-end font-light">Counter-Strafe</th>
                  </thead>
                  <tbody class="flex flex-col flex-1 rounded-lg gap-[2px]">
                    <tr
                      class="flex h-16 flex-row bg-gray-900 hover:bg-gray-900 relative w-full gap-6 pr-6"
                      @click="navToClip(score)"
                      :class="{
                        'first-border': index == 0,
                        'second-border': index == 1,
                        'third-border': index == 2,
                        'cursor-pointer': score.allstar_clip
                      }"
                      v-for="(score, index) in scores.slice(0, 3)"
                      :key="score"
                    >
                      <div v-if="index == 0" class="w-full h-full absolute top-0 left-0 overflow-hidden z-10">
                        <div class="w-full h-full topography-color opacity-20 bg-[url('/img/icons/topography.svg')]"></div>
                      </div>
                      <td class="pl-6 py-8 font-medium text-xl flex flex-row z-20 flex-1">
                        <div
                          v-for="(user, userIndex) in score.users"
                          :key="user"
                          class="flex flex-row items-center"
                        >
                          <a
                            class="flex"
                          >
                            <div class="flex flex-row items-center">
                              <div
                                class="w-10 h-10 mr-4 shrink-0 rounded-full relative"
                              >
                                <img
                                  :src="user?.steam_profile?.avatarfull"
                                  class="object-contain rounded-full"
                                />
                                <div class="w-5 h-5 rounded-full absolute -bottom-0.5 -right-1 border-2 border-gray-800 text-black text-xs flex items-center justify-center"
                                  :class="{
                                    'first-bg': index == 0,
                                    'second-bg': index == 1,
                                    'third-bg': index == 2,
                                }">
                                  {{ index + 1 }}
                                </div>
                              </div>
                              <div class="flex flex-col justify-center text-text-primary shrink-1">
                                <p class="flex items-center gap-2">
                                  <h1>{{ username(user) }}</h1>
                                  <div class="has-tooltip flex cursor-pointer">
                                    <img v-if="user?.uses_wooting" class="w-4 h-4 has-tooltip" src="/img/icons/wooting.svg" />
                                    <span
                                    class="tooltip rounded shadow-md p-1 bg-gray-700 text-[10px] ml-6 px-2 -mt-2"
                                    >Verified Wooting keyboard run</span>
                                  </div>

                                </p>
                                <p class="text-sm text-text-secondary">
                                  {{ moment(score.created_at).format("LL") }}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </td>
                      <td class="flex flex-col items-end justify-center sm:min-w-24">
                        <p class="font-medium text-xl text-text-primary">{{ getScore(score.score) }}</p>
                      </td>

                      <td v-if="activeMod === 'Rapid'" class="flex items-end justify-center flex-col sm:min-w-24">
                        <p class="text-xl font-medium text-text-primary">{{ getAverageCounterStrafe(score) }}%</p>
                      </td>

                      <!-- <img v-if="index === 0" src="/img/wooting-prize.png" class="h-8 absolute -top-2 -right-6 rotate-45 select-none" /> -->

                    </tr>
                    <tr
                      class="bg-gray-900 shadow h-12 flex flex-row text-text-primary pl-6 items-center gap-6 pr-6"
                      v-for="(score, index) in scores.slice(3)"
                      :key="score"
                      @click="navToClip(score)"
                      :class="{
                        'cursor-pointer': score.allstar_clip
                      }"
                    >
                      <td class="flex-1 flex flex-row items-center gap-2">
                        <div
                          class="bg-gray-800 rounded-full h-6 w-6 flex justify-center items-center text-text-secondary"
                        >
                          {{ index + 4 }}
                        </div>
                        <div
                          class="py-4 font-medium text-md flex flex-row items-center"
                          v-for="(user, index) in score.users"
                          :key="user"
                        >
                          <a
                            class="text-text-primary text-sm"
                          >
                            <p class="mr-2 flex gap-2 items-center">
                              {{ username(user)}}
                              <div class="has-tooltip flex cursor-pointer">
                                <img v-if="user?.uses_wooting" class="w-4 h-4 has-tooltip" src="/img/icons/wooting.svg" />
                                <span
                                class="tooltip rounded shadow-md p-1 bg-gray-700 text-[10px] ml-6 px-2 -mt-2"
                                >Verified Wooting keyboard run</span>
                              </div>
                              <span v-if="index + 1 < score.users.length">, </span>
                            </p>
                          </a>
                        </div>
                      </td>
                      <td class="flex flex-col items-end justify-center min-w-24">
                        <p class="font-medium text-xl">{{ getScore(score.score) }}</p>
                      </td>

                      <td v-if="activeMod === 'Rapid'" class="flex items-end justify-center flex-col min-w-24">
                        <p class="text-xl font-medium">{{ getAverageCounterStrafe(score) }}%</p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-else-if="!loading" class="w-full bg-gray-900 mt-5 rounded-lg p-6 flex gap-4 items-center">
                  <div class="flex flex-col gap-4 w-full">
                    <div class="w-8 h-8 rounded-full bg-info-900 flex items-center justify-center">
                      <InformationCircleIcon class="w-5 h-5 text-info-400"/>
                    </div>
                    <h1 class="text-text-primary text-xl font-semibold">No Global Scores Found</h1>
                    <p class="text-text-secondary text-sm">
                      This will update with the latest scores
                    <br/> when the first user plays the gamemode!
                    </p>
                  </div>
                  <!-- <button @click="openStartServerModal()" class="rounded-md bg-primary-600 text-text-primary text-xs font-medium flex items-center justify-center py-2.5 w-2/3 hover:bg-primary-500">
                    Play Now
                  </button> -->
                </div>
              </div>

              <!-- user scores -->
              <div class="flex-1 pt-10">
                <div class="flex items-center">
                  <div class="flex flex-col flex-1">
                    <div class="text-text-primary leading-6 font-medium text-[24px] pb-3 tracking-[.5px]">Your Top 10</div>
                    <h2 class="text-text-secondary text-sm">Your Attempts ― {{ userHighScoreCount }}</h2>
                  </div>
                  <router-link :to="`/user/${user.uuid}`" class="px-3 py-2.5 rounded-md bg-gray-800 text-text-primary text-xs hover:bg-gray-700 flex gap-2 items-center">
                    More Statistics
                    <ArrowRightIcon class="h-3 w-3"/>
                  </router-link>
                </div>
                <table v-if="userScores?.length > 0" class="w-full mt-5">
                  <thead class="bg-gray-900 shadow h-10 flex flex-row text-text-secondary text-xs px-6 items-center justify-center border-b-2 border-gray-950 rounded-t-lg w-full gap-6">
                    <th class="flex-1 flex flex-row items-start font-light">Name</th>
                    <th class="flex flex-row min-w-24 items-end justify-end font-light">Score</th>
                    <th v-if="activeMod === 'Rapid'" class="flex flex-row min-w-24 items-end justify-end font-light">Counter-Strafe</th>
                  </thead>
                  <tbody class="flex flex-col flex-1 rounded-lg gap-[2px]">
                    <div class="flex flex-col gap-[2px]">
                      <tr
                        class="flex min-h-16 flex-row bg-gray-900 hover:bg-gray-900 relative w-full gap-6 pr-6 first-border"
                        v-for="(score, index) in userScores.slice(0, 1)"
                        :key="score"
                        @click="navToClip(score)"
                        :class="{
                          'cursor-pointer': score.allstar_clip
                        }"
                      >
                        <div v-if="index == 0" class="w-full h-full absolute top-0 left-0 overflow-hidden z-10">
                          <div class="w-full h-full topography-color opacity-20 bg-[url('/img/icons/topography.svg')]"></div>
                        </div>
                        <td class="pl-6 flex-1 font-medium text-xl flex flex-row z-20">
                          <div
                            v-for="(user, userIndex) in score.users"
                            :key="user"
                            class="flex flex-row items-center"
                          >
                            <a
                              class="flex"
                            >
                              <div class="flex flex-row items-center">
                                <div
                                  class="w-10 h-10 mr-4 shrink-0 rounded-full relative"
                                >
                                  <img
                                    :src="user?.steam_profile?.avatarfull"
                                    class="object-contain rounded-full"
                                  />
                                  <div class="w-5 h-5 rounded-full absolute -bottom-0.5 -right-1 border-2 border-gray-800 text-black text-xs flex items-center justify-center"
                                    :class="{
                                      'first-bg': index == 0,
                                      'second-bg': index == 1,
                                      'third-bg': index == 2,
                                  }">
                                    {{ index + 1 }}
                                  </div>
                                </div>
                                <div class="flex flex-col justify-center text-text-primary shrink-1">
                                  <p class="flex items-center gap-2">
                                    {{ username(user) }}
                                    <div class="has-tooltip flex cursor-pointer">
                                      <img v-if="user?.uses_wooting" class="w-4 h-4 has-tooltip" src="/img/icons/wooting.svg" />
                                      <span
                                      class="tooltip rounded shadow-md p-1 bg-gray-700 text-[10px] ml-6 px-2 -mt-2"
                                      >Verified Wooting keyboard run</span>
                                    </div>
                                  </p>

                                  <p class="text-sm text-text-secondary">
                                    {{ moment(score.created_at).format("LL") }}
                                  </p>
                                </div>
                              </div>
                              <PlusIcon
                                class="mr-6 text-text-secondary"
                                v-if="userIndex + 1 < score.users.length"
                              />
                            </a>
                          </div>
                        </td>
                        <td class="flex flex-col items-end justify-center sm:min-w-24">
                          <p class="font-medium text-xl text-text-primary">{{ getScore(score.score) }}</p>
                        </td>

                        <td v-if="activeMod === 'Rapid'" class="flex items-end justify-center flex-col sm:min-w-24">
                          <p class="text-xl font-medium text-text-primary">{{ getAverageCounterStrafe(score) }}%</p>
                        </td>
                      </tr>

                      <div v-if="userScores.slice(0, 1)[0].allstar_clip" class="flex flex-col bg-gray-900 p-4 gap-4 border-b-2 border-gray-950">
                        <div class="flex items-center gap-2">
                          <h1 class="text-text-primary text-lg font-semibold flex-1">Replay</h1>
                          <div
                            v-clipboard:copy="`https://play.refrag.gg/leaderboards/${userScores.slice(0, 1)[0].uuid}`"
                            v-clipboard:success="copySucceeded"
                            target="_blank"
                            class="bg-primary-600 duration-300 px-4 py-2 hover:bg-primary-500 cursor-pointer text-text-primary text-xs flex gap-2 items-center justify-center rounded-md"
                          >
                            <UploadIcon class="w-3 h-3 text-text-primary" />
                            Share
                          </div>
                        </div>

                        <div class="flex-[7] aspect-[16/9] w-full bg-gray-800 rounded-lg overflow-hidden">
                          <iframe
                            v-if="userScores.slice(0, 1)[0]?.allstar_clip?.status == 'processed' || userScores.slice(0, 1)[0]?.allstar_clip?.status == 'submitted'"
                            :key="userScores.slice(0, 1)[0]?.allstar_clip?.status"
                            class="w-full h-full"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                            :src="userScores.slice(0, 1)[0]?.allstar_clip?.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
                          >
                          </iframe>
                          <img v-else-if="highScore?.allstar_clip?.status == 'failed'" src="https://imagedelivery.net/5wML_ikJr-qv52ESeLE6CQ/cfb3370d-92be-4245-5048-75e430af0e00/public" alt="Loading" class="w-full h-full" />
                          <img v-else src="https://imagedelivery.net/5wML_ikJr-qv52ESeLE6CQ/wordpress.refrag.gg/2024/05/processing.jpg/public" alt="Loading" class="w-full h-full" />
                        </div>
                      </div>
                    </div>

                    <tr
                      class="flex h-16 flex-row bg-gray-900 hover:bg-gray-900 relative w-full gap-6 pr-6"
                      :class="{
                        'second-border': index == 0,
                        'third-border': index == 1,
                        'cursor-pointer': score.allstar_clip
                      }"
                      v-for="(score, index) in userScores.slice(1, 3)"
                      :key="score"
                      @click="navToClip(score)"
                    >
                      <td class="pl-6 py-8 flex-1 font-medium text-xl flex flex-row z-20">
                        <div
                          v-for="(user, userIndex) in score.users"
                          :key="user"
                          class="flex flex-row items-center"
                        >
                          <a
                            class="flex"
                          >
                            <div class="flex flex-row items-center">
                              <div
                                class="w-10 h-10 mr-4 shrink-0 rounded-full relative"
                              >
                                <img
                                  :src="user?.steam_profile?.avatarfull"
                                  class="object-contain rounded-full"
                                />
                                <div class="w-5 h-5 rounded-full absolute -bottom-0.5 -right-1 border-2 border-gray-800 text-black text-xs flex items-center justify-center"
                                  :class="{
                                    'second-bg': index == 0,
                                    'third-bg': index == 1,
                                }">
                                  {{ index + 2 }}
                                </div>
                              </div>
                              <div class="flex flex-col justify-center text-text-primary shrink-1">
                                <p class="flex items-center gap-2">
                                  {{ username(user) }}
                                  <div class="has-tooltip flex cursor-pointer">
                                    <img v-if="user?.uses_wooting" class="w-4 h-4 has-tooltip" src="/img/icons/wooting.svg" />
                                    <span
                                    class="tooltip rounded shadow-md p-1 bg-gray-700 text-[10px] ml-6 px-2 -mt-2"
                                    >Verified Wooting keyboard run</span>
                                  </div>
                                </p>

                                <p class="text-sm text-text-secondary">
                                  {{ moment(score.created_at).format("LL") }}
                                </p>
                              </div>
                            </div>
                            <PlusIcon
                              class="mr-6 text-text-secondary"
                              v-if="userIndex + 1 < score.users.length"
                            />
                          </a>
                        </div>
                      </td>
                      <td class="flex flex-col items-end justify-center sm:min-w-24">
                        <p class="font-medium text-xl text-text-primary">{{ getScore(score.score) }}</p>
                      </td>

                      <td v-if="activeMod === 'Rapid'" class="flex items-end justify-center flex-col sm:min-w-24">
                        <p class="text-xl font-medium text-text-primary">{{ getAverageCounterStrafe(score) }}%</p>
                      </td>
                    </tr>
                    <tr
                      class="bg-gray-900 shadow h-12 flex flex-row text-text-primary pl-6 items-center gap-6 pr-6"
                      v-for="(score, index) in userScores.slice(3)"
                      :key="score"
                      @click="navToClip(score)"
                      :class="{
                        'cursor-pointer': score.allstar_clip
                      }"
                    >
                      <td class="flex-1 flex flex-row items-center gap-2">
                        <div
                          class="bg-gray-800 rounded-full h-6 w-6 flex justify-center items-center text-text-secondary"
                        >
                          {{ index + 4 }}
                        </div>
                        <div
                          class="py-4 font-medium text-md flex flex-row items-center"
                          v-for="(user, index) in score.users"
                          :key="user"
                        >
                          <a
                            class="text-text-primary text-sm"
                          >
                            <p class="mr-2 flex gap-2 items-center">
                              {{ username(user)}}
                              <div class="has-tooltip flex cursor-pointer">
                                <img v-if="user?.uses_wooting" class="w-4 h-4 has-tooltip" src="/img/icons/wooting.svg" />
                                <span
                                class="tooltip rounded shadow-md p-1 bg-gray-700 text-[10px] ml-6 px-2 -mt-2"
                                >Verified Wooting keyboard run</span>
                              </div>
                              <span v-if="index + 1 < score.users.length">, </span>
                            </p>
                          </a>
                        </div>
                      </td>
                      <td class="flex flex-col items-end justify-center sm:min-w-24">
                        <p class="font-medium text-xl">{{ getScore(score.score) }}</p>
                      </td>

                      <td v-if="activeMod === 'Rapid'" class="flex items-end justify-center flex-col sm:min-w-24">
                        <p class="text-xl font-medium">{{ getAverageCounterStrafe(score) }}%</p>
                      </td>
                    </tr>
                  </tbody>
                </table>


                <div v-else-if="!loading" class="w-full bg-gray-900 mt-5 rounded-lg p-6 flex gap-4 items-center">
                  <div class="flex flex-col gap-4 w-full">
                    <div class="w-8 h-8 rounded-full bg-info-900 flex items-center justify-center">
                      <InformationCircleIcon class="w-5 h-5 text-info-400"/>
                    </div>
                    <h1 class="text-text-primary text-xl font-semibold">No Scores Found</h1>
                    <p class="text-text-secondary text-sm">
                      Start playing now to earn your inaugural<br> points and ascend the leaderboard ranks!
                    </p>
                  </div>
                  <!-- <button @click="openStartServerModal()" class="rounded-md bg-primary-600 text-text-primary text-xs font-medium flex items-center justify-center py-2.5 w-2/3 hover:bg-primary-500">
                    Play Now
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    :showMapSelector="showMapSelector"
    @close="showServerModal = false"
  />
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      scores: [],
      userScores: [],
      selected_map: null,
      scoreType: this.$route.query.score || "score",
      maps: [],
      stats: {},
      activeMod: this.$route.query.mod || "Crossfire",
      userStatsLoading: false,
      globalStatsLoading: false,
      mods: ["Rapid", "Crossfire", "Prefire", "Blitz", "Spray Transfer"],
      userHighScoreCount: 0,
      season: parseInt(this.$route.query.season) + 1 || 5,
      serverInfo: {},
      showServerModal: false,
      showMapSelector: true,
    };
  },
  head: {
    title: "Refrag | Leaderboards"
  },
  computed: {
    user() {
      return this.$auth.user() || {};
    },
    prizes() {
      return [];
    },
    formattedMod() {
      let mod = this.activeMod;
      if (mod === "Spray Transfer") {
        return mod = "spray";
      }
      return mod.toLowerCase();
    },
    cs_maps() {
      return this.$store.state?.modInfo?.mods.find(
        (mod) => mod.name?.toLowerCase() == this.formattedMod
      )?.cs_maps
        .filter((map) => map.cs2)
        .sort((a, b) => a.friendly_name.localeCompare(b.friendly_name));
    },
  },
  created() {
    if (this.cs_maps) {
      let urlMap = this.$route.query.map || "de_mirage";
      this.selected_map =
        this.cs_maps.find((map) => map.name == urlMap) || this.cs_maps[0];
      this.getScores();
    }
    return true;
  },
  watch: {
    cs_maps() {
      if (this.cs_maps && !this.selected_map) {
        let urlMap = this.$route.query.map || "de_mirage";
        this.selected_map =
          this.cs_maps.find((map) => map.name == urlMap) || this.cs_maps[0];
        this.getScores();
      }
    },
  },
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    getUserLink(user) {
      if (user.username) {
        return user.username;
      }
      return user.uuid;
    },
    navToClip(score) {
      if (!score.allstar_clip) return;
      this.$router.push(`/leaderboards/${score.uuid}`)
    },
    getMapFriendlyName(map) {
      if(this.scoreType === "elo") {
        return "All Maps";
      }
      return map?.friendly_name;
    },
    copySucceeded() {
      this.showMessage({
        messageType: "success",
        messageText: "Code Copied to Clipboard",
      });
      setTimeout(() => {
        this.hideMessage();
      }, 3000);
    },
    selectMap(map) {
      this.selected_map = map;
      this.$router.push({ query: { map: this.selected_map?.name, score: this.scoreType, mod: this.activeMod, season: this.season - 1 } });
      this.getScores();
    },
    selectScoreType(type) {
      this.scoreType = type;
      this.$router.push({ query: { map: this.selected_map?.name, score: this.scoreType, mod: this.activeMod, season: this.season - 1 } });
      if (type === "elo") {
        this.getElos();
      } else {
        this.getScores();
      }
    },
    selectMod(mod) {
      this.activeMod = mod;
      this.$router.push({ query: { map: this.selected_map?.name, score: this.scoreType, mod: this.activeMod, season: this.season - 1 } });
      if (this.scoreType === "elo") {
        this.getElos();
      } else {
        this.getScores();
      }
    },
    selectSeason(season) {
      this.season = season;
      this.$router.push({ query: { map: this.selected_map.name, score: this.scoreType, mod: this.activeMod, season: this.season - 1 } });
      if (this.scoreType === "elo") {
        this.getElos();
      } else {
        this.getScores();
      }
    },
    username(user) {
      if (user.username) {
        return user.username;
      }
      return user?.steam_profile?.personaname;
    },
    getTotalAttempts() {
      return this.stats.total_high_scores || 0;
    },
    getScores() {
      let limit = this.activeMod === "Rapid" ? 50 : 10;
      this.userStatsLoading = true;
      this.globalStatsLoading = true;
      if (!this.selected_map) {
        this.userStatsLoading = false;
        this.globalStatsLoading = false;
        return;
      }
      this.getStats();
      axios
        .get(
          `game_modes/user_high_scores?map_name=${
            this.selected_map.name
          }&score_type=solo&game_type=${this.formattedMod}&season=${this.season}`
        )
        .then(({ data }) => {
          this.userScores = data;
          this.userHighScoreCount = data.length;
          this.userStatsLoading = false;
        });
      axios
        .get(
          `game_modes/score_leaderboard?map_name=${
            this.selected_map.name
          }&score_type=solo&game_type=${this.formattedMod}&season=${this.season}&custom_limit=${limit}`
        )
        .then(({ data }) => {
          this.scores = data;
          this.globalStatsLoading = false;
        });
    },
    getElos() {
      this.userStatsLoading = true;
      this.globalStatsLoading = true;
      axios
        .get(`game_modes/elo_leaderboard?game_type=${this.formattedMod}`)
        .then(({ data }) => {
          this.scores = data;
          this.globalStatsLoading = false;
        });
      axios.get(`game_modes/user_high_elos?game_type=${this.formattedMod}`).then(({ data }) => {
        this.userScores = data;
        this.userStatsLoading = false;
      });
    },
    getStats() {
      axios
        .get(`game_modes/stats?game_type=${this.formattedMod}&season=${this.season}&map_name=${this.selected_map.name}`)
        .then(({ data }) => {
          this.stats = data;
        });
    },
    getScore(score) {
      if ((this.activeMod === "Prefire" || this.activeMod === "Rapid") && this.scoreType == "score") {
        return (score / 1000).toFixed(2);
      }
      return score;
    },
    launchRapid() {
      this.serverInfo = {
        mode: "Rapid",
      }
      this.showServerModal = true;
    },
    getAverageCounterStrafe(score) {
      // Check if score.statistics is an array
      if(!Array.isArray(score.statistics)) {
        return score.statistics.average_counter_strafe.toFixed(0);
      }
      if(score.statistics[0]?.average_counter_strafe) {
        return score.statistics[0]?.average_counter_strafe?.toFixed(0);
      }
      // If we have multiple statistics, grab the one where average_counter_strafe is not null
      let averageCounterStrafe = score.statistics.find((stat) => stat.average_counter_strafe !== null);
      if(averageCounterStrafe) {
        return averageCounterStrafe.average_counter_strafe.toFixed(0);
      }
      return '?';
    }
  },
};
</script>

<style>

.first-border {
  border-left: 3px solid transparent;
  border-image-source: linear-gradient(180deg, #F7A94C 0%, #F7E64C 100%);
  border-image-slice: 1;
}

.second-border {
  border-left: 3px solid transparent;
  border-image-source: linear-gradient(180deg, #959595 0%, #EEEEEE 100%);
  border-image-slice: 1;
}

.third-border {
  border-left: 3px solid transparent;
  border-image-source: linear-gradient(180deg, #F68D4F 0%, #FBB083 100%);
  border-image-slice: 1;
}

.first-bg {
  background-image: linear-gradient(180deg, #F7A94C 0%, #F7E64C 100%);
}

.second-bg {
  background-image: linear-gradient(180deg, #959595 0%, #EEEEEE 100%);
}

.third-bg {
  background-image: linear-gradient(180deg, #F68D4F 0%, #FBB083 100%);
}

.first-background {
  background-image: url("../../assets/topography.svg");
  background-size: 100% 100%;
}

.topography-color {
  filter: invert(99%) sepia(33%) saturate(5352%) hue-rotate(319deg) brightness(110%) contrast(105%);
}

</style>
