<template>
  <div class="w-full flex flex-col gap-9">
    <div v-if="props.activeUserData && props.activeUserMatchData" class="flex flex-col gap-4 w-full">
      <div class="flex items-center">
        <h1 class="text-text-primary text-2xl font-semibold flex-1">Summary</h1>
        <button
          @click="updateTab()"
          class="py-2.5 px-3 bg-gray-800 text-text-primary text-xs z-50 cursor-pointer hover:bg-gray-700 duration-300 rounded-lg"
        >
          See more
        </button>
      </div>

      <div class="grid grid-cols-3 w-full gap-6">
        <StatBlock
          v-for="stat in props.activeUserTwoLowest"
          :key="stat.title"
          :stat="stat"
        />

        <div class="flex flex-col rounded-lg bg-gray-900 divide-y divide-gray-950">
          <div class="flex gap-2 items-center flex-1 px-4">
            <h2 class="text-text-secondary text-sm flex-1">Enemies Flashed</h2>
            <h1 class="text-text-primary text-lg font-medium">
              {{ props.activeUserMatchData?.match_performance?.enemies_flashed }}
            </h1>
          </div>
          <div class="flex gap-2 items-center flex-1 px-4">
            <h2 class="text-text-secondary text-sm flex-1">K/D Ratio</h2>
            <h1 class="text-text-primary text-lg font-medium">{{ kdr }}</h1>
          </div>
          <div class="flex gap-2 items-center flex-1 px-4">
            <h2 class="text-text-secondary text-sm flex-1">ADR</h2>
            <h1 class="text-text-primary text-lg font-medium">
              {{
                props.activeUserMatchData?.match_performance?.average_damage_per_round.toFixed(
                  2
                )
              }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4 w-full">
      <div class="flex items-center gap-3">
        <h1 class="text-text-primary text-2xl font-semibold flex-1">Scoreboard</h1>
        <div class="flex flex-row bg-gray-800 p-1 rounded-md">
          <div
            @click="updateSelectedTeams('all')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedTeams === 'all'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            All
          </div>
          <div
            @click="updateSelectedTeams('winningteam')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedTeams === 'winningteam'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            {{ teamALabel }}
          </div>
          <div
            @click="updateSelectedTeams('losingteam')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedTeams === 'losingteam'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            {{ teamBLabel }}
          </div>
        </div>

        <div class="flex flex-row bg-gray-800 p-1 rounded-md">
          <div
            @click="updateSelectedStats('general')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedStats === 'general'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            General
          </div>
          <div
            @click="updateSelectedStats('aim')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedStats === 'aim'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            Aim
          </div>
          <div
            @click="updateSelectedStats('trades')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedStats === 'trades'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            Entry & Trades
          </div>
          <div
            @click="updateSelectedStats('utility')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedStats === 'utility'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            Utility
          </div>
        </div>
      </div>

      <div
        v-if="selectedTeams === 'winningteam' || selectedTeams === 'all'"
        class="bg-gray-900 rounded-lg shadow-lg text-text-primary text-sm"
      >
        <table class="w-full text-right">
          <thead>
            <tr
              v-if="selectedStats === 'general'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/2">{{ teamALabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Kills / Deaths / Assists"
              >
                K / D / A
              </th>
              <th class="py-4 px-4 font-light cursor-pointer" title="Kill / Death ratio">
                K/D
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of shots hit that were headshots"
              >
                HS%
              </th>
              <th class="py-4 px-4 font-light cursor-pointer" title="Kills per round">
                KPR
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average Damage per round"
              >
                ADR
              </th>
              <th
                class="py-4 font-light px-4 cursor-pointer"
                title="Percent of rounds in which player killed someone, assisted, stayed alive, or was traded"
              >
                KAST
              </th>
            </tr>
            <tr
              v-else-if="selectedStats === 'aim'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/2">{{ teamALabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average time to kill a player starting from the moment you see them in seconds"
              >
                TTK
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average time to damage a player starting from the moment you see them in seconds"
              >
                TTD
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of bullets hit when you can see someone"
              >
                Spotted Acc
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of kills that were caused by a headshot"
              >
                HS Kill %
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="The average of how many degrees you have to move your crosshair to damage someone after seeing them"
              >
                Crosshair Placement
              </th>
              <th
                class="py-4 font-light px-4 cursor-pointer"
                title="How many bullets hit the target when spraying (3 or more bullets)"
              >
                Recoil Accuracy
              </th>
            </tr>
            <tr
              v-else-if="selectedStats === 'trades'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/3">{{ teamALabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="You got the first kill in a round"
              >
                Opening Kills
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="You were the first person to die in a round"
              >
                Opening Deaths
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Killing the killer of a teammate within 5 seconds"
              >
                Trades
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Having the opportunity (seeing the enemy) to trade your teammate but not killing them"
              >
                Trade Fails
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your deaths were traded"
              >
                Deaths Traded
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your deaths were untradable"
              >
                Trade Death Fails
              </th>
            </tr>
            <tr v-else-if="selectedStats === 'utility'" class="text-sm text-text-secondary text-nowrap">
              <th class="py-4 px-4 font-light text-left w-1/3">{{ teamALabel }}</th>
              <th class="py-4 px-4 font-light">Flash Assists</th>
              <th class="py-4 px-4 font-light">Enemies Flashed</th>
              <th class="py-4 px-4 font-light">Friends Flashed</th>
              <th class="py-4 px-4 font-light">Avg Flash Time</th>
              <th class="py-4 px-4 font-light">Util Damage</th>
              <th class="py-4 font-light px-4">Unused Util Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="selectedStats === 'general'" v-for="(player, index) in winningTeam" :key="index" class="border-t border-gray-950 text-nowrap">
              <td class="py-2 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-8 h-8 rounded-full"
                />
                <div>
                  <span>{{ playerName(player) }}</span>
                </div>
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.kills }} /
                {{ player.match_performance.deaths }} /
                {{ player.match_performance.assists }}
              </td>
              <td class="py-2 px-4">
                {{
                  calculateKD(
                    player.match_performance.kills,
                    player.match_performance.deaths
                  )
                }}
              </td>
              <td class="py-2 px-4">
                {{ (player.match_performance.headshot_percentage * 100).toFixed(0) }}%
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.kills_per_round.toFixed(2) }}
              </td>
              <td class="py-2 px-4">
                {{ Math.round(player.match_performance.average_damage_per_round) }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.kast?.toFixed(2) }}</td>
            </tr>
            <tr v-if="selectedStats === 'aim'" v-for="(player, index) in winningTeam" :key="index" class="border-t border-gray-950 text-nowrap">
              <td class="py-2 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-8 h-8 rounded-full"
                />
                <div>
                  <span>{{ playerName(player) }}</span>
                </div>
              </td>
              <td class="py-2 px-4">
                {{ convertNanoSeconds(player.match_performance.time_to_kill) }}s
              </td>
              <td class="py-2 px-4">
                {{ convertNanoSeconds(player.match_performance.time_to_damage) }}s
              </td>
              <td class="py-2 px-4">
                {{ (player.match_performance.spotted_accuracy * 100).toFixed(0) }}%
              </td>
              <td class="py-2 px-4">
                {{
                  (player.match_performance.headshot_kill_percentage * 100).toFixed(0)
                }}%
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.crosshair_placement.toFixed(2) }}°
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.recoil_control_accuracy.toFixed(0) }}%
              </td>
            </tr>
            <tr v-if="selectedStats === 'trades'" v-for="(player, index) in winningTeam" :key="index" class="border-t border-gray-950 text-nowrap">
              <td class="py-2 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-8 h-8 rounded-full"
                />
                <div>
                  <span>{{ playerName(player) }}</span>
                </div>
              </td>
              <td class="py-2 px-4">{{ player.match_performance.opening_success }}</td>
              <td class="py-2 px-4">{{ player.match_performance.opening_fails }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.trade_kill_successes }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.trade_kill_fails }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.traded_death_successes }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.traded_death_fails }}</td>
            </tr>
            <tr v-if="selectedStats === 'utility'" v-for="(player, index) in winningTeam" :key="index" class="border-t border-gray-950 text-nowrap">
              <td class="py-2 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-8 h-8 rounded-full"
                />
                <div>
                  <span>{{ playerName(player) }}</span>
                </div>
              </td>
              <td class="py-2 px-4">{{ player.match_performance.flash_assists }}</td>
              <td class="py-2 px-4">{{ player.match_performance.enemies_flashed }}</td>
              <td class="py-2 px-4">{{ player.match_performance.friends_flashed }}</td>
              <td class="py-2 px-4">{{ player.match_performance.average_flash_time.toFixed(2) }}s</td>
              <td class="py-2 px-4">{{ player.match_performance.utility_damage }}</td>
              <td class="py-2 px-4">
                ${{ player.match_performance.unused_utility_value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="selectedTeams === 'losingteam' || selectedTeams === 'all'"
        class="bg-gray-900 rounded-lg shadow-lg text-text-primary text-sm"
      >
        <table class="w-full text-right">
          <thead>
            <tr v-if="selectedStats === 'general'" class="text-sm text-text-secondary text-nowrap">
              <th class="py-4 px-4 font-light text-left w-1/2">{{ teamBLabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Kills / Deaths / Assists"
              >
                K / D / A
              </th>
              <th class="py-4 px-4 font-light cursor-pointer" title="Kill / Death ratio">
                K/D
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of shots hit that were headshots"
              >
                HS%
              </th>
              <th class="py-4 px-4 font-light cursor-pointer" title="Kills per round">
                KPR
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average Damage per round"
              >
                ADR
              </th>
              <th
                class="py-4 font-light px-4 cursor-pointer"
                title="Percent of rounds in which player killed someone, assisted, stayed alive, or was traded"
              >
                KAST
              </th>
            </tr>
            <tr v-else-if="selectedStats === 'aim'" class="text-sm text-text-secondary text-nowrap">
              <th class="py-4 px-4 font-light text-left w-1/2">{{ teamBLabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average time to kill a player starting from the moment you see them in seconds"
              >
                TTK
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average time to damage a player starting from the moment you see them in seconds"
              >
                TTD
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of bullets hit when you can see someone"
              >
                Spotted Acc
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of kills that were caused by a headshot"
              >
                HS Kill %
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="The average of how many degrees you have to move your crosshair to damage someone after seeing them"
              >
                Crosshair Placement
              </th>
              <th
                class="py-4 font-light px-4 cursor-pointer"
                title="How many bullets hit the target when spraying (3 or more bullets)"
              >
                Recoil Accuracy
              </th>
            </tr>
            <tr v-else-if="selectedStats === 'trades'" class="text-sm text-text-secondary text-nowrap">
              <th class="py-4 px-4 font-light text-left w-1/3">{{ teamBLabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="You got the first kill in a round"
              >
                Opening Kills
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="You were the first person to die in a round"
              >
                Opening Deaths
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Killing the killer of a teammate within 5 seconds"
              >
                Trades
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Having the opportunity (seeing the enemy) to trade your teammate but not killing them"
              >
                Trade Fails
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your deaths were traded"
              >
                Deaths Traded
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your deaths were untradable"
              >
                Trade Death Fails
              </th>
            </tr>
            <tr v-else-if="selectedStats === 'utility'" class="text-sm text-text-secondary text-nowrap">
              <th class="py-4 px-4 font-light text-left w-1/3">{{ teamBLabel }}</th>
              <th class="py-4 px-4 font-light cursor-pointer">Flash Assists</th>
              <th class="py-4 px-4 font-light">Enemies Flashed</th>
              <th class="py-4 px-4 font-light">Friends Flashed</th>
              <th class="py-4 px-4 font-light">Avg Flash Time</th>
              <th class="py-4 px-4 font-light">Util Damage</th>
              <th class="py-4 font-light px-4">Unused Util Value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="selectedStats === 'general'" v-for="(player, index) in losingTeam" :key="index" class="border-t border-gray-950 text-nowrap">
              <td class="py-2 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-8 h-8 rounded-full"
                />
                <div>
                  <span>{{ playerName(player) }}</span>
                </div>
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.kills }} /
                {{ player.match_performance.deaths }} /
                {{ player.match_performance.assists }}
              </td>
              <td class="py-2 px-4">
                {{
                  calculateKD(
                    player.match_performance.kills,
                    player.match_performance.deaths
                  )
                }}
              </td>
              <td class="py-2 px-4">
                {{ (player.match_performance.headshot_percentage * 100).toFixed(0) }}%
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.kills_per_round.toFixed(2) }}
              </td>
              <td class="py-2 px-4">
                {{ Math.round(player.match_performance.average_damage_per_round) }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.kast?.toFixed(2) }}</td>
            </tr>
            <tr v-if="selectedStats === 'aim'" v-for="(player, index) in losingTeam" :key="index" class="border-t border-gray-950 text-nowrap">
              <td class="py-2 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-8 h-8 rounded-full"
                />
                <div>
                  <span>{{ playerName(player) }}</span>
                </div>
              </td>
              <td class="py-2 px-4">
                {{ convertNanoSeconds(player.match_performance.time_to_kill) }}s
              </td>
              <td class="py-2 px-4">
                {{ convertNanoSeconds(player.match_performance.time_to_damage) }}s
              </td>
              <td class="py-2 px-4">
                {{ (player.match_performance.spotted_accuracy * 100).toFixed(0) }}%
              </td>
              <td class="py-2 px-4">
                {{
                  (player.match_performance.headshot_kill_percentage * 100).toFixed(0)
                }}%
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.crosshair_placement.toFixed(2) }}°
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.recoil_control_accuracy.toFixed(0) }}%
              </td>
            </tr>
            <tr v-if="selectedStats === 'trades'" v-for="(player, index) in losingTeam" :key="index" class="border-t border-gray-950 text-nowrap">
              <td class="py-2 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-8 h-8 rounded-full"
                />
                <div>
                  <span>{{ playerName(player) }}</span>
                </div>
              </td>
              <td class="py-2 px-4">{{ player.match_performance.opening_success }}</td>
              <td class="py-2 px-4">{{ player.match_performance.opening_fails }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.trade_kill_successes }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.trade_kill_fails }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.traded_death_successes }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.traded_death_fails }}</td>
            </tr>
            <tr v-if="selectedStats === 'utility'" v-for="(player, index) in losingTeam" :key="index" class="border-t border-gray-950 text-nowrap">
              <td class="py-2 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-8 h-8 rounded-full"
                />
                <div>
                  <span>{{ playerName(player) }}</span>
                </div>
              </td>
              <td class="py-2 px-4">{{ player.match_performance.flash_assists }}</td>
              <td class="py-2 px-4">{{ player.match_performance.enemies_flashed }}</td>
              <td class="py-2 px-4">{{ player.match_performance.friends_flashed }}</td>
              <td class="py-2 px-4">{{ player.match_performance.average_flash_time.toFixed(2) }}s</td>
              <td class="py-2 px-4">{{ player.match_performance.utility_damage }}</td>
              <td class="py-2 px-4">
                ${{ player.match_performance.unused_utility_value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, defineEmits } from "vue";
import StatBlock from "./StatBlock.vue";
import HighlightBlock from "./HighlightBlock.vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import axios from "axios";
import refragIcon from "../../assets/logos/refrag-icon-button.svg";

const props = defineProps({
  matchData: {
    type: Object,
    required: true,
  },
  activeUserTwoLowest: Object,
  activeUserMatchData: Object,
  activeUserData: Object,
});

const emit = defineEmits([
  "updateActiveTab",
  "updateActiveUserTwoLowest",
  "updateActiveUserMatchData",
]);
const auth = useAuth();
const user = ref(auth.user());
const selectedTeams = ref("all");
const selectedStats = ref("general");

const playerName = (player) => {
  return player.player?.steam_profile?.personaname || player.player?.name;
};

const playerAvatar = (player) => {
  return player.player?.steam_profile?.avatar || refragIcon;
};

const calculateKD = (kills, deaths) => {
  return (kills / deaths).toFixed(2);
};

const teamALabel = computed(() => (isMatchTied.value ? "Team A" : "Winning Team"));
const teamBLabel = computed(() => (isMatchTied.value ? "Team B" : "Losing Team"));

const isMatchTied = computed(() => {
  return props.matchData?.winning_team === 0;
});

const winningTeam = computed(() => {
  if (isMatchTied.value) {
    return props.matchData?.players?.filter(
      (player) => player.match_performance.last_round_team === 2
    );
  }
  return props.matchData?.players?.filter(
    (player) => player.match_performance.last_round_team === props.matchData?.winning_team
  );
});

const losingTeam = computed(() => {
  if (isMatchTied.value) {
    return props.matchData?.players?.filter(
      (player) => player.match_performance.last_round_team === 3
    );
  }
  return props.matchData?.players?.filter(
    (player) => player.match_performance.last_round_team !== props.matchData?.winning_team
  );
});

function getActiveUserTwoLowest() {
  // Ensure userData and userMatchData are available
  if (!props.activeUserData || !props.activeUserMatchData) {
    return [];
  }

  // Extract user data and match performance
  const userStats = props.activeUserData.stats;
  const matchStats = props.activeUserMatchData?.match_performance;

  let differences = [];

  // Iterate over the keys in userStats
  for (const key in userStats) {
    // Skip the key if it doesn't exist in matchStats
    if (!matchStats[key]) {
      continue;
    }

    // if (!userStats[key].recommended_mods?.length) {
    //   continue;
    // }

    // Calculate the difference between the user's average and the match performance
    let difference;
    let percentDifference;

    // Adjust the difference calculation based on the is_ascending flag
    if (userStats[key].is_ascending) {
      // For ascending stats, a negative difference means the match performance is worse
      difference = matchStats[key] - userStats[key].rating_range_average_value;
    } else {
      // For descending stats, a positive difference means the match performance is worse
      difference = userStats[key].rating_range_average_value - matchStats[key];
    }

    // Calculate the percentage difference
    let playerValue =
      userStats[key].rating_range_average_value === 0
        ? 1
        : userStats[key].rating_range_average_value;
    percentDifference = (difference / playerValue) * 100;

    if (percentDifference > 0) {
      continue;
    }

    // Add the difference to the differences array
    differences.push({
      title: key,
      value: matchStats[key],
      difference: difference.toFixed(2),
      percentDifference: percentDifference.toFixed(2),
      unit_type: userStats[key].unit_type,
      is_ascending: userStats[key].is_ascending,
    });
  }

  // Sort the differences to get the stats with the most significant percentage negative impact first
  differences.sort((a, b) => a.percentDifference - b.percentDifference);

  // Return the two stats with the largest negative percentage differences
  emit("updateActiveUserTwoLowest", differences.slice(0, 2));
}

const kdr = computed(() => {
  return (
    props.activeUserMatchData?.match_performance?.kills /
    props.activeUserMatchData?.match_performance?.deaths
  ).toFixed(2);
});

function updateTab() {
  emit("updateActiveTab", "statistics");
}

function convertNanoSeconds(nanoSeconds) {
  return (nanoSeconds / 1000000000).toFixed(2);
}

function updateSelectedTeams(team) {
  selectedTeams.value = team;
}

function updateSelectedStats(stat) {
  selectedStats.value = stat;
}

function getActiveUserMatchData() {
  // Ensure matchData is available and has players
  if (!props.matchData || !props.matchData?.players) {
    return null;
  }
  // Find and return the specific player's data based on steam_id
  emit(
    "updateActiveUserMatchData",
    props.matchData.players.find(
      (player) => player.player.steam_id == user.value.steam_id
    ) || null
  );
}

watch(
  () => props.matchData,
  () => {
    if (props.matchData?.players) {
      getActiveUserMatchData();
    }
  }
);

watch(
  () => [props.activeUserData, props.activeUserMatchData],
  () => {
    if (props.activeUserData && props.activeUserMatchData) {
      getActiveUserTwoLowest();
    }
  }
);

if (!props.activeUserMatchData) {
  getActiveUserMatchData();
}
</script>
