export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export function formatStatString(statString) {
  return statString
    // Add spaces before capital letters
    .replace(/([A-Z])/g, ' $1')
    // Remove any underscores and trim extra spaces
    .replace(/_/g, ' ').trim()
    // Convert to lowercase
    .toLowerCase();
}
export function formatData(val, type) {
  let formattedStat;
  let value = Math.abs(parseFloat(val));

  switch (type) {
    case 'integer':
      formattedStat = value.toFixed(2);
      break;
    case 'percentage':
      formattedStat = (value * 100).toFixed(0);
      break;
    case 'decimal':
      formattedStat = value.toFixed(2);
      break;
    case 'degrees':
      formattedStat = value.toFixed(1);
      break;
    case 'seconds':
      formattedStat = value.toFixed(2);
      break;
    case 'nanoseconds':
      formattedStat = (value / 1000000000).toFixed(2);
      break;
    default:
      formattedStat = value;
      break;
  }

  return formattedStat;
}

export function formatStatStringData(val, type) {
  let formattedStat = formatData(val, type);
  
  switch (type) {
    case 'percentage':
      return formattedStat + '%';
    case 'degrees':
      return formattedStat + '°';
    case 'seconds':
      return formattedStat + 's';
    default:
      return formattedStat.toString();
  }
}
